export const doFilter = (activeFilters, items) => {
    return [...items.filter(it => {
        for (var key in activeFilters) {
            let _filter = activeFilters[key];
            if (_filter.type === 'sort' || _filter.type === 'keyword') {
                activeFilters.splice(key, 1);
            }
        }
        let matched = true
        if (activeFilters.length === 0) matched = true
        // styles
        const styleFilters = activeFilters.filter(a => a.type === 'style') || []
        const colourFilters = activeFilters.filter(a => a.type === 'colour') || []
        const homeTypeFilters = activeFilters.filter(a => a.type === 'hometype') || []
        const roomFilters = activeFilters.filter(a => a.type === 'room') || []
        const storeysFilters = activeFilters.filter(a => a.type === 'storeys') || []
        const bedFilters = activeFilters.filter(a => a.type === 'bed') || []
        const bathFilters = activeFilters.filter(a => a.type === 'bath') || []
        const garageFilters = activeFilters.filter(a => a.type === 'garage') || []
        const livingFilters = activeFilters.filter(a => a.type === 'living') || []
        const brandFilters = activeFilters.filter(a => a.type === 'brand') || []
        const categoryFilters = activeFilters.filter(a => a.type === 'categories') || []
    
        if (it.styleArray && styleFilters.length) matched = matched && it.styleArray.some(sa => styleFilters.some(sf => sf.value == sa))
        if (it.colorArray && colourFilters.length) matched = matched && it.colorArray.some(ca => colourFilters.some(cf => cf.value == ca))
        if (it.homeTypes && homeTypeFilters.length) matched = matched && it.homeTypes.some(ht => homeTypeFilters.some(htf => htf.value == ht))
        if (it.roomAmenities && roomFilters.length) matched = matched && it.roomAmenities.some(ra => roomFilters.some(rf => rf.value == ra))
        if (it.storeys && storeysFilters.length) matched = matched && it.storeys.some(sr => storeysFilters.some(sf => sf.value == sr))

        // handle floor value
        if (it.bed && bedFilters.length) matched = matched && it.bed.some(b => bedFilters.some(bf => bf.value == Math.floor(b)))
        if (it.bath && bathFilters.length) matched = matched && it.bath.some(b => bathFilters.some(bf => bf.value == Math.floor(b)))
        if (it.garage && garageFilters.length) matched = matched && it.garage.some(g => garageFilters.some(gf => gf.value == Math.floor(g)))
        if (it.living && livingFilters.length) matched = matched && it.living.some(l => livingFilters.some(lf => lf.value == Math.floor(l)))

        if (it.brands && brandFilters.length) matched = matched && it.brands.some(b => brandFilters.some(bf => bf.value == b))
        if (it.categoryArray && categoryFilters.length) matched = matched && it.categoryArray.some(ca => categoryFilters.some(cf => cf.value == ca))
    
        return matched
    })]
}

export const filterVariant = (activeFilters, variant) => {
    let valid = false
    const styleFilters = activeFilters.filter(a => a.type === 'style') || []
    const colourFilters = activeFilters.filter(a => a.type === 'colour') || []
    const storeysFilters = activeFilters.filter(a => a.type === 'storeys') || []

    if (variant.styleID) valid = valid || styleFilters.some(sf => sf.value == variant.styleID)
    if (variant.colourID) valid = valid || colourFilters.some(cf => cf.value == variant.colourID)
    if (variant.storeysID) valid = valid || storeysFilters.some(sf => sf.value == variant.storeysID)

    if (variant.Styles) valid = valid || styleFilters.some(sf => variant.Styles.some(vs => vs.UndigitalProductStyle.objectID == sf.value))
    if (variant.Colours) valid = valid || colourFilters.some(cf => variant.Colours.some(vc => vc.UndigitalColour.objectID == cf.value))

    return valid || (styleFilters.length + colourFilters.length + storeysFilters.length === 0)
}