import React from "react"
import { useStaticQuery, graphql} from 'gatsby';

const SearchBar = () => {
    const HomeTypes = useStaticQuery(graphql`
    {
      allSilverStripeDataObject(filter: {className: {eq: "Undigital__Objects__HomeType"}}) {
        nodes {
          UndigitalHomeType {
            name
            objectID
            sortOrder
          }
        }
      }
    }
  `);
const allHomeTypes = HomeTypes.allSilverStripeDataObject.nodes;
const search =  typeof window !== 'undefined' ? window.location.search : '/';

const params = new URLSearchParams(search);

const _current_hometype = params.get('hometype');
const _current_bed = params.get('bed');
const _current_bath = params.get('bath');
const _current_garage = params.get('garage');
const _current_living = params.get('livingspace');

  return (
    <div className="search-bar">
      <div className="container">
        <form id="designsfilter" action="/home-designs/">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-xl-1 d-none d-xl-block"></div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2">
              <div className="form-group mb-xl-0">
                <label className="sr-only" htmlFor="inputBedrooms">
                  Storey
                </label>
                <select
                  name="hometype"
                  id="inputStorey"
                  className="select-dropdown has-icon icon-home"
                  data-style="true"
                  autoComplete="off"
                  title="Storey"
                  defaultValue={_current_hometype}
                >
                  <option value="">All</option>
                  {allHomeTypes.map(item => (
                          <option key={item.UndigitalHomeType.objectID} value={item.UndigitalHomeType.objectID}>{item.UndigitalHomeType.name}</option>
                    ))};
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2">
              <div className="form-group mb-xl-0">
                <label className="sr-only" htmlFor="inputBedrooms">
                  Bedrooms
                </label>
                <select
                  name="bed"
                  id="inputBedrooms"
                  className="select-dropdown has-icon icon-bed"
                  data-style="true"
                  autoComplete="off"
                  title="Bedrooms"
                  defaultValue={_current_bed}
                >
                  <option value="">All</option>
                  <option value="1">1 Bedroom</option>
                  <option value="2">2 Bedrooms</option>
                  <option value="3">3 Bedrooms</option>
                  <option value="4">4 Bedrooms</option>
                  <option value="5">5 Bedrooms</option>
                  <option value="6">6 Bedrooms</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2">
              <div className="form-group mb-xl-0">
                <label className="sr-only" htmlFor="inputBathrooms">
                  Bathrooms
                </label>
                <select
                  name="bath"
                  id="inputBathrooms"
                  className="select-dropdown has-icon icon-bath"
                  data-style="true"
                  autoComplete="off"
                  title="Bathrooms"
                  defaultValue={_current_bath}
                >
                  <option value="">All</option>
                  <option value="1">1 Bathroom</option>
                  <option value="1.5">1.5 Bathrooms</option>
                  <option value="2">2 Bathrooms</option>
                  <option value="2.5">2.5 Bathrooms</option>
                  <option value="3">3 Bathrooms</option>
                  <option value="3.5">3.5 Bathrooms</option>
                  <option value="4">4 Bathrooms</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2">
              <div className="form-group mb-xl-0">
                <label className="sr-only" htmlFor="inputGarages">
                  Garages
                </label>
                <select
                  name="garage"
                  id="inputGarages"
                  className="select-dropdown has-icon icon-garage"
                  data-style="true"
                  autoComplete="off"
                  title="Garages"
                  defaultValue={_current_garage}
                >
                  <option value="">All</option>
                  <option value="1">1 Garage</option>
                  <option value="2">2 Garages</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2 LivingSpaces">
              <div className="form-group mb-xl-0">
                <label className="sr-only" htmlFor="inputLivingSpaces Spaces">
                  Living Spaces
                </label>
                <select
                  name="livingspace"
                  id="inputLivingSpaces"
                  className="select-dropdown has-icon icon-sofa"
                  data-style="true"
                  autoComplete="off"
                  title="Living Spaces"
                  defaultValue={_current_living}
                >
                  <option value="">All</option>
                  <option value="1">1 Living Space</option>
                  <option value="2">2 Living Spaces</option>
                  <option value="3">3 Living Spaces</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-1 d-none d-sm-block"></div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SearchBar
