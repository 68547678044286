import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import ShowMoreText from "react-show-more-text"
import { Swiper, SwiperSlide } from "swiper/react"
import config from "../../../config/config"

function cutString(s, n){
    if(s){
        s = s.toString()
        s = s.replace( /(<([^>]+)>)/ig, '');
        var cut = s.indexOf(' ', n)
        if(cut === -1) return s
        return s.substring(0, cut)
    }
}

const QuickViewModalData = ({ show, handleClose, item, hidePricingGlobally }) => {
  const [slideOption, setSlideOption] = useState(null)
  const [isReadmore, setReadmore] = useState(true)
  const changePopupSlideImage = slide => {
    setSlideOption(slide)
  }

  useEffect(() => setSlideOption(null), [item])

  const modal = useMemo(() => {
    const swiper_params = {
      spaceBetween: 10,
      navigation: {
        prevEl: ".swiper-button-prev-unique",
        nextEl: ".swiper-button-next-unique",
      },
      breakpoints: {
        1400: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }
    const host = config.host
    var isShowing = show
    const valid_status = ["Active"]
    var className = item.className ? item.className : item.ClassName
    var image_path = item.Image
    var viewLink
    var isFloorplan = false
    var other_options = []
    var firstOption = null
    let vt = null
    var about = item.about
    var short_about
    if(typeof about !== 'undefined' && about != ''){
        short_about = cutString(about, 310)
    }
    
    switch (className) {
        case "BoardImage":
            var product = item.ProductData
            item.name = product.Name
            item.about = null
            if(item.ProductType === 'Furniture'){
                item.about = product.About
            }else if(item.ProductType === 'HomeDesign'){
                item.about = product.Description
            }else if(item.ProductType === 'Facade'){
                item.about = product.About
            }else if(item.ProductType === 'FinishesFixtures'){
                item.about = product.About
            }else if(item.ProductType === 'Cabinetry'){
                item.about = product.Description
            }else if(item.ProductType === 'Cabinetry'){
                item.about = product.About
            }
            
            viewLink = product.Link
            if(typeof item.about !== 'undefined' && item.about != ''){
                short_about = item.about ? cutString(item.about, 340) : ''
            }
            image_path = item.ImagePath
            if(product.vt){
                firstOption = {
                    name: product.vt.Name,
                }
            }
            break
      case "Cabinetry":
        var variations = item.Variations
        item.about = item.description
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalCabinetryVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrPrice * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.featureImagePath ? option.featureImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
                if(!firstOption) firstOption = option
                other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
            if(typeof item.vt === 'object'){
                image_path = vt.Image
                firstOption = vt
            }
        }
        break
      case "Furniture":
        var variations = item.Variations
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalFurnitureVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrp * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.productImagePath ? option.productImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
                if(!firstOption) firstOption = option
                other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {            
            if(typeof item.vt === 'object'){
                image_path = vt.Image
                firstOption = vt
            }          
        }
        break
      case "Selection":
        var variations = item.Items
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalSelectionItem
            option.formattedPrice = null
            option.Image = option.productImagePath ? option.productImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
                if(!firstOption) firstOption = option
                other_options.push(option)
            }
            j++
          }
        }
        if(image_path == null)
            image_path = item.imagePath
        break
      case "Facade":
        var variations = item.Variations
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalFacadeVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrPrice * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.featureImagePath ? option.featureImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
                if(!firstOption) firstOption = option
                other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
            if(typeof item.vt === 'object'){
                image_path = vt.Image
                firstOption = vt
            }         
        }
        break
      case "FinishesFixtures":
        var variations = item.Variations
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalFinishesFixturesVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrp * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.featureProductImagePath ? option.featureProductImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
                if(!firstOption) firstOption = option
                other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
            if(typeof item.vt === 'object'){
                image_path = vt.Image
                firstOption = vt
            }
        }
        break
      case "FeaturedProduct":
        break
      default:
        break
    }
    let vtType = typeof item.vt
    
    if(className !== 'BoardImage'){
        viewLink = item.link
        if(vtType === 'object'){
            viewLink = item.link + (slideOption && slideOption.objectID ? `/?vid=${slideOption.objectID}` : (item.vt && item.vt.objectID ? `/?vid=${item.vt.objectID}` : '/'))
        }else if(vtType === 'number'){
            viewLink = item.link + `/?vid=${item.vt}`
        }
    }
    
    return <Modal
      show={isShowing}
      onHide={handleClose}
      onShow={resize}
      size={"xl"}
      animation={true}
      className="itemModal"
    >
      <div className="row">
        <div className="col-12">
          <div className="main-title-wrap">
            <div className="board-page-title flex-1">
              <h2 className="product-name-title text-uppercase h2">
                {item.name}
              </h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 col-xl-8">
          <div
            className="btn-close"
            onClick={handleClose}
          ></div>
          <h3 className="info-title">{slideOption ? slideOption.name : (firstOption ? firstOption.name : item.name)}</h3>
          <div className={`itemModal-wrap mt-20${isFloorplan ? ' is-floorplan' : ''}`}>
            {(slideOption || image_path) && (
              <img
                src={slideOption ? slideOption.Image : image_path}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="col-12 col-lg-5 col-xl-4">
          <h3 className="info-title">Information</h3>
          <div className="list-group list-group-flush equal-height mb-4">
            {item.about !== '' && (
              <div className="list-group-item px-0 about-wrap">
                <span className="d-none d-lg-block" dangerouslySetInnerHTML={{ __html: short_about }} />
                <ShowMoreText
                  lines={4}
                  className="content-wrap d-block d-lg-none"
                  anchorClass="d-none"
                  expanded={false}
                  width={0}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: short_about }}
                  />
                </ShowMoreText>
              </div>
            )}
            {/* {(item.rrp && hidePricingGlobally == 0) && (
              <div className="list-group-item px-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">COST OVER STANDARD</h5>
                  </div>
                  <div className="col-auto">
                    <p className="font-weight-medium">{item.rrp}</p>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className="text-center my-lg-5 my-4 pt-lg-4">
        <a href={viewLink} className="btn btn-black btn-lg btn-w210">
          VIEW PRODUCT
        </a>
      </div>
    </Modal>
  }, [show, handleClose, item, hidePricingGlobally, slideOption])

  function resize() {
    window.dispatchEvent(new Event("resize"))
  }

  return <>{modal}</>
}

export default QuickViewModalData
