import React, { useCallback, useMemo } from "react"
import { connect } from 'react-redux'
import { toggleFilter } from "../../state/filter"
import { getUser } from "../../services/auth"
import RenderHomeType from "../../components/Filter/RenderHomeType"
import { doFilter } from "../../services/filter"
import { useStaticQuery, graphql } from "gatsby"

const SidebarFilter = ({ dispatch, activeFilters, currentCategory, items }) => {
  const user = getUser();
  const Location = user.Location;
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const onChange = value => {
  }
  
  var filters = [];
  var filterList = {
    '': [
      'Style', 'HomeType', 'Room', 'PriceSlider'/*, 'Designer' */
    ],
    'explore': [
      'Style', 'HomeType', 'Room', 'PriceSlider'/*, 'Designer' */
    ],
    'home-designs': [
                /*'HomeType', */'Storeys', 'Bed', 'Bath', 'Garage', 'LivingSpaces', 'Room', 'PriceSlider',/* 'LotWidth'*/
    ],
    'facades': [
      'Storeys'/*'Style', 'HomeType', 'Storeys', 'PriceSlider'*/
    ],
    'land': [
      'AddressSearch', 'LandArea', 'LandSize', 'PriceSlider'
    ],
    'style': [
      'Style', 'PriceSlider'/*, 'Designer' */
    ],
    'finishes-fixtures': [
      'SubCategory'/*, 'Style'*/, 'Colour', 'Category',/* 'Finish', 'PriceSlider', 'Brand'*/
    ],
    'furniture': [
      /*'SubCategory', 'Style', 'Colour', 'PriceSlider', 'Brand'*/
    ],
    'cabinetry': [
      /*'SubCategory', 'Style', 'Room', 'Colour', 'PriceSlider'*/
    ]
  };

  if (typeof filterList[currentCategory] !== 'undefined') {
    filters = filterList[currentCategory];
  }
  
  const query = useStaticQuery(graphql`
    {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
            UndigitalGlobalConfig {
                hidePricingGlobally
                hideStyleSection
                hideHomeDesignSection
                hideFacadesSection    
                hideFinishesFixturesSection
                hideCabinetrySection
                hideFurnitureSection
            }
        }
    }
`)
    const globalConfig = query.silverStripeDataObject.UndigitalGlobalConfig
    var categories = [
      { name: "All", URLSegment: "/explore/", linkClass: "link" }
    ]
    if(globalConfig.hideStyleSection === 0){
        categories.push({ name: "Style", URLSegment: "/explore/style/", linkClass: currentCategory === 'style' ? 'active' : 'link' })
    }
    if(globalConfig.hideHomeDesignSection === 0){
        categories.push({ name: "Home Designs", URLSegment: "/explore/home-designs/", linkClass: currentCategory === 'home-designs' ? 'active' : 'link' })
    }
    if(globalConfig.hideFacadesSection === 0){
        categories.push({ name: "Facades", URLSegment: "/explore/facades/", linkClass: currentCategory === 'facades' ? 'active' : 'link' })
    }
    if(globalConfig.hideFinishesFixturesSection === 0){        
        categories.push({ name: "Fixtures & Finishes", URLSegment: "/explore/finishes-fixtures/", linkClass: currentCategory === 'finishes-fixtures' ? 'active' : 'link' })
    }
    if(globalConfig.hideCabinetrySection === 0){
        categories.push({ name: "Cabinetry", URLSegment: "/explore/cabinetry/", linkClass: currentCategory === 'cabinetry' ? 'active' : 'link' })
    }
    if(globalConfig.hideFurnitureSection === 0){
        categories.push({ name: "Furniture", URLSegment: "/explore/furniture/", linkClass: currentCategory === 'furniture' ? 'active' : 'link' })
    }
    
  const categoryNav = useMemo(() => {
    return (
      <ul className="category-nav">
        {categories.map((item, idx) => (
          <li key={`category-${idx}`}>
            <a href={`${item.URLSegment}`} className={item.linkClass}>{item.name}</a>
          </li>
        ))}
      </ul>
    )
  }, [currentCategory])

  const onRenderItem = useCallback(filter => {
    if (!items) return true;
    const _filters = doFilter([filter], items)
    return _filters.length > 0
  }, [items])

  return (
    <>
      <div className="sidebar-category">
        {categoryNav}
      </div>
      {filters.length > 0 && (
        <>
          <div className="sub-title sub-title-filter d-none d-lg-flex">
            <span>filter</span>
            <button
              className="filter-reset"
              onClick={() => {
                window.location.href = url;
              }}
            >
              <span>RESET</span>
              <img src="/img/explore/refresh.svg" />
            </button>
          </div>
          <div className="filter-modal-container filter-modal-container-custom">
            <RenderHomeType isMobile={false} list={filters} activeFilters={activeFilters} onSelectItem={filter => {
              dispatch(toggleFilter(filter))
            }} onRenderItem={filter => onRenderItem(filter)} />
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentCategory: state.nav.current,
})

export default connect(mapStateToProps, null)(SidebarFilter)