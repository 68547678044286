import React from "react"

const ContactUs = () => {
  return (
    <section className="section bg-lightgray experience">
      <div className="container text-center">
        <h2 className="title title-long title-long-dark">
          Experience Style &amp; Simplicity
        </h2>
        <div className="overview-txt">
          <p>
            Value style and upfront pricing and information? Enjoy a style led
            experience and honest interactions with Birdy.
          </p>
        </div>
        <a href="/homes/contact/" className="btn btn-primary btn-rounded">
          CONTACT US
        </a>
      </div>
    </section>
  )
}

export default ContactUs
